import Language from "../../Interfaces/Language";

export const pt_pt: Language = {
  common: {
    help: "Precisa de ajuda?",
    helpCenter: "Centro de Ajuda",
    expect: "O que esperar destes testes",
    faq: "Perguntas Mais Frequentes",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/auauabtj7j-perguntas-mais-frequentes-portuguese-portugal",
    copyright: "Direitos de autor",
    copyrightNotices: "Avisos de Direitos de Autor",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/ncnj3lqnq5-aviso-de-direitos-autorais-portuguese-portugal",
    terms: "Termos de Utilização",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/idsfxvp3vm-termos-e-condi-es-de-utiliza-o-portuguese-portugal",
    privacy: "Declaração de Privacidade",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/0nkshv6sb3-pol-tica-de-privacidade-portuguese-portugal",
    dmca: "Política de DMCA",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/gaov0mzyck-pol-tica-do-digital-millenium-copyright-act-portuguese-portugal",
    accommodations: "Deficiência/Alojamento",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/h0zrsdbbl6-defici-ncia-alojamento-portuguese-portugal",
    technicalSupport: "Suporte Técnico",
    chatSupport:
      "Para obter suporte, converse com a nossa equipa através do chat no canto inferior direito desta página."
  },
  instruction: {
    eppCopyright:
      "Esta avaliação inclui material reproduzido do SalesAP ©1995 e do CSAP ©2002, Multi-Health Systems Inc. Todos os direitos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "A sua avaliação foi pausada.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "<b>Não</b> saia da janela do seu navegador nem clique fora da área de avaliação enquanto o teste estiver a decorrer.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Se está a ter problemas de conectividade à Internet, ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "clique aqui",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " para obter algumas dicas de resolução de problemas.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Ao abandonar a avaliação poderá ser <b>bloqueado</b> por razões de segurança do teste, o que poderá impedir que complete a sua avaliação.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Se abandonar a avaliação <b>mais 2 vezes</b>, será <b>bloqueado</b> por razões de segurança do teste.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Se abandonar a avaliação novamente, será bloqueado por razões de segurança do teste.</b>",
    BUTTON_RESUME: "Retomar"
  },
  error: {
    common: {
      UH_OH: "Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Não foi possível carregar a página devido a problemas de rede. Verifique a sua ligação à Internet e atualize a página dentro de alguns momentos.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "Se o problema persistir, consulte as ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Perguntas Frequentes de Assistência",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/auauabtj7j-perguntas-mais-frequentes-portuguese-portugal"
    }
  }
};
