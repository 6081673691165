import React from "react";
import { ProgressBar } from "react-bootstrap";

interface Props {
  width: number;
  text?: string;
  isRTL?: boolean;
}

const ProgressBarComponent = ({ width, text, isRTL = false }: Props) => {
  return <ProgressBar now={width} label={text} dir={isRTL ? "rtl" : "ltr"} />;
};

export default ProgressBarComponent;
