import Language from "../../Interfaces/Language";

export const sk: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://www.criteriacorp.com/candidates/resource-hub",
    expect: "What to expect on these tests",
    expectLink: "https://www.criteriacorp.com/candidates/resource-hub",
    faq: "Frequently Asked Questions",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/sy65kl98y7-naj-astej-ie-ot-zky-slovak",
    copyright: "Autorské práva",
    copyrightNotices: "Doložky o autorských právach",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/vvapte45nb-dolo-ky-o-autorsk-ch-pr-vach-slovak",
    terms: "Podmienky používania ",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/8o9hfryg0l-podmienky-pou-vania-slovak",
    privacy: "Vyhlásenie o ochrane osobných údajov",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/klfuobewv7-vyhl-senie-o-ochrane-osobn-ch-dajov-slovak",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/bmejm504vn-politika-s-ladu-so-z-konom-o-autorsk-ch-pr-vach-pre-digit-lne-tis-cro-ie-slovak",
    accommodations: "Zdravotné postihnutie/prispôsobenie",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/7sd4b6xvfq-zdravotn-postihnutie-prisp-sobenie-slovak",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  instruction: {
    eppCopyright:
      "Toto hodnotenie zahŕňa materiály prevzaté zo SalesAP ©1995 a CSAP ©2002, Multi-Health Systems Inc. Všetky práva vyhradené.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA autorské práva © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "približne",
    minutes: "min."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Vaše hodnotenie bolo pozastavené.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "V priebehu testu <b>neopúšťajte</b> okno prehliadača ani neklikajte mimo hodnotiacej plochy.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Ak sa vyskytnú problémy s pripojením na internet, ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "kliknite sem",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " a zobrazia sa tipy na riešenie problémov.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "V prípade, že opustíte hodnotenie, bude váš test z bezpečnostných dôvodov <b>zablokovaný</b> a môže sa stať, že nebudete môcť hodnotenie dokončiť.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Upozorňujeme, že ak opustíte hodnotenie ešte dvakrát, bude váš test z bezpečnostných dôvodov <b>zablokovaný</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>V prípade, že znova opustíte hodnotenie, bude váš test z bezpečnostných dôvodov zablokovaný.</b>",
    BUTTON_RESUME: "Pokračovať"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/sy65kl98y7-naj-astej-ie-ot-zky-slovak"
    }
  }
};
