import Language from "../../Interfaces/Language";

export const tr: Language = {
  common: {
    help: "Yardıma mı ihtiyacınız var?",
    helpCenter: "Yardım Merkezi",
    helpCenterLink: "https://www.criteriacorp.com/candidates/resource-hub",
    expect: "Bu testlerden ne beklenmelidir",
    expectLink: "https://www.criteriacorp.com/candidates/resource-hub",
    faq: "Sıkça Sorulan Sorular",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/5oy5zk3ytg-s-k-a-sorulan-sorular-turkish",
    copyright: "Telif Hakkı",
    copyrightNotices: "Telif Hakkı Bildirimleri",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/vjks76vvpq-telif-hakk-bildirimi-turkish",
    terms: "Kullanım Şartları",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/tm0f38pfci-kullan-m-h-k-m-ve-ko-ullari-turkish",
    privacy: "Gizlilik Bildirimi",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/em3j3usyv9-gizlilik-politikas-turkish",
    dmca: "DMCA Politikası",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/sz0j93b8oe-dijital-milenyum-telif-hakk-yasas-politikas-turkish",
    accommodations: "Engellilik / Erişilebilirlik",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/5rtdbxsd7p-engellilik-eri-ilebilirlik-turkish",
    technicalSupport: "Teknik Destek",
    chatSupport:
      "Destek için lütfen bu sayfanın sağ alt köşesinden ekibimizle sohbet edin"
  },
  instruction: {
    eppCopyright:
      "This assessment includes material reproduced from SalesAP ©1995 and CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Değerlendirmeniz duraklatıldı.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Test devam ederken lütfen tarayıcı pencerenizden <b>çıkmayın</b> veya değerlendirme alanının dışına tıklamayın.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "İnternet bağlantı sorunları yaşıyorsanız, sorun giderme ipuçları için ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "buraya tıklayın",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2: ".",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Değerlendirmeden çıkmanız, test güvenliği sebebiyle <b>hesabınızın kilitlenmesine</b> yol açabilir ve bu da değerlendirmenizi tamamlamanızı engelleyebilir.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Değerlendirmeden <b>2 kez daha</b> çıkarsanız test güvenliği sebebiyle <b>hesabınız kilitlenecektir</b>.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Değerlendirmeden tekrar çıkarsanız test güvenliği sebebiyle hesabınız kilitlenecektir.</b>",
    BUTTON_RESUME: "Devam Et"
  },
  error: {
    common: {
      UH_OH: "Ah!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Ağ sorunları nedeniyle yüklenemiyor. Lütfen internet bağlantınızı kontrol edin ve sayfayı birkaç dakika içinde yenileyin.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "Sorun devam ederse lütfen şuraya bakın: ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "destek SSS’leri",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/5oy5zk3ytg-s-k-a-sorulan-sorular-turkish"
    }
  }
};
