import Language from "../../Interfaces/Language";

export const pt_br: Language = {
  common: {
    help: "Precisa de ajuda?",
    helpCenter: "Central de Ajuda",
    expect: "O que esperar desses testes",
    faq: "Perguntas frequentes",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/itci2v8ant-perguntas-frequentes-portuguese-brazil",
    copyright: "Diretos autorais",
    copyrightNotices: "Avisos de Direitos Autorais",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/erjwlok5jd-aviso-de-direitos-autorais-portuguese-brazil",
    terms: "Termos de Uso",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/tzgfjqqdod-termos-e-condi-es-de-uso-portuguese-brazil",
    privacy: "Declaração de Privacidade",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/lrrdl3myxj-pol-tica-de-privacidade-portuguese-brazil",
    dmca: "Política de DMCA",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/9dqmv96skq-pol-tica-do-digital-millenium-copyright-act-portuguese-brazil",
    accommodations: "Necessidades especiais/adequações",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/8bn4vm04tm-necessidades-especiais-adequa-es-portuguese-brazil",
    technicalSupport: "Suporte técnico",
    chatSupport:
      "Se precisar de ajuda, entre em contato com nossa equipe de suporte no canto inferior direito desta página"
  },
  instruction: {
    eppCopyright:
      "Esta avaliação inclui material reproduzido do SalesAP ©1995 e CSAP ©2002, Multi-Health Systems Inc. Todos os direitos reservados.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Sua avaliação foi pausada.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "<b>Não</b> saia da janela do seu navegador nem clique fora da área de avaliação enquanto o teste estiver em andamento.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Se estiver com problemas na conexão à internet, ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "clique aqui",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " para algumas dicas de resolução de problemas.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Sair da avaliação vai fazer com que você seja <b>bloqueado(a)</b> por motivos de segurança do teste, o que pode impedir que você termine sua avaliação.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Observe que, se você sair da avaliação mais 2 vezes, será <b>bloqueado(a)</b> por motivos de segurança do teste.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Se sair da avaliação novamente, você será bloqueado(a) por razões de segurança do teste.</b>",
    BUTTON_RESUME: "Retomar"
  },
  error: {
    common: {
      UH_OH: "Ih!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Não foi possível carregar devido a problemas de rede. Verifique sua conexão com a Internet e atualize a página em instantes.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "Se o problema persistir, verifique nossas ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "perguntas frequentes de suporte",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/itci2v8ant-perguntas-frequentes-portuguese-brazil"
    }
  }
};
