import Language from "../../Interfaces/Language";

export const ar: Language = {
  common: {
    help: "Need Help?",
    helpCenter: "Help Centre",
    helpCenterLink: "https://www.criteriacorp.com/candidates/resource-hub",
    expect: "What to expect on these tests",
    expectLink: "https://www.criteriacorp.com/candidates/resource-hub",
    faq: "Frequently Asked Questions",
    faqLink: "https://criteriacandidates.helpdocs.io/article/n3duaev22o-arabic",
    copyright: "حقوق الطبع والنشر محفوظة © 2024 لشركة Criteria Corp.",
    copyrightNotices: "إشعارات حقوق النشر",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/mm12qau1le-arabic",
    terms: "شروط الاستخدام",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/ml34bu4hq0-arabic-tpx-bca-dck-loa",
    privacy: "بيان الخصوصية",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/7c6vyzgnyp-arabic-tpx-bca",
    dmca: "DMCA Policy",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/77asm0dyzk-arabic-tpx-bca-dck",
    accommodations: "الإعاقة / الترتيبات التيسيرية",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/3g1nwcvv66-arabic-tpx",
    technicalSupport: "Technical Support",
    chatSupport:
      "For support, please chat with our team on the bottom right hand corner of this page"
  },
  overview: {
    approx: "تقريبًا",
    minutes: "دقيقة/دقائق"
  },
  instruction: {
    eppCopyright:
      "يتضمن هذا التقييم موادًا أُعيد إنتاجها من SalesAP ©1995 وCSAP ©2002, Multi-Health Systems Inc.. جميع الحقوق محفوظة.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA autorské práva © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Your Assessment Has Been Paused.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Please <b>do not</b> leave your browser window or click outside of the assessment area while the test is in progress.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "If you are experiencing internet connectivity issues, please ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "click here",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " for some troubleshooting tips.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Leaving the assessment will cause you to be <b>locked out</b> for test security reasons, which may prevent you from completing your assessment. ",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Please note that if you leave the assessment 2 more times, you will be <b>locked out</b> for test security reasons. ",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>If you leave the assessment again, you will be locked out for test security reasons.</b>",
    BUTTON_RESUME: "Resume"
  },
  error: {
    common: {
      UH_OH: "Uh Oh!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Unable to load due to network issues. Please check your internet connection and refresh the page in a few moments.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "If the problem persists, please check our ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "Support FAQs",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/n3duaev22o-arabic"
    }
  }
};
