import Language from "../../Interfaces/Language";

export const ko: Language = {
  common: {
    help: "도움이 필요하십니까?",
    helpCenter: "고객 센터",
    expect: "테스트 내용",
    faq: "자주 묻는 질문",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/qnwidrp1b6-korean-lvo-jxe-cod-ztc",
    copyright: "저작권",
    copyrightNotices: "저작권 공고",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/ty85sahu2h-korean-lvo-jxe-cod",
    terms: "이용약관",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/tivt3mxmmj-korean-lvo",
    privacy: "개인정보 정책",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/7ruteius9a-korean",
    dmca: "DMCA 정책",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/8ntz5qkhqg-korean-lvo-jxe-cod-ztc-rgn",
    accommodations: "장애/편의 사항",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/d1r87j81p3-korean-lvo-jxe",
    technicalSupport: "기술 지원",
    chatSupport:
      "도움이 필요하면 이 페이지의 오른쪽 하단에 있는 팀 채팅 기능을 이용하십시오."
  },
  instruction: {
    eppCopyright:
      "This assessment includes material reproduced from SalesAP ©1995 and CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Copyright © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "평가가 일시 중단되었습니다.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "시험이 진행되는 동안 브라우저 창에서 나가거나 평가 영역 밖을 클릭하지 <b>마십시오</b>.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "인터넷 연결 문제가 발생하는 경우, 문제 해결 팁을 보려면 ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "여기를 클릭하십시오",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2: ".",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "평가 영역에서 나가면 시험 보안상의 이유로 다시 <b>돌아오지 못하게</b>, 이는 평가를 완료하지 못하게 할 수 있습니다.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "평가 영역에서 <b>2번 더</b> 나가는 경우, 보안상의 이유로 다시 <b>돌아오지 못하게</b> 됩니다.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>다시 평가 영역에서 나가는 경우, 보안 상의 이유로 다시 돌아오지 못하게 됩니다.</b>",
    BUTTON_RESUME: "이어서 하기"
  },
  error: {
    common: {
      UH_OH: "이런!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "네트워크 문제로 인해 로드할 수 없습니다. 인터넷 연결을 확인하고 몇 초 후 페이지를 새로 고치세요.",
      MESSAGE_PARAGRAPH_2_SECTION_1: "문제가 지속되면 ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "자주 묻는 질문 지원",
      MESSAGE_PARAGRAPH_2_SECTION_2: "을 확인해 주세요.",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/qnwidrp1b6-korean-lvo-jxe-cod-ztc"
    }
  }
};
