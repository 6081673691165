import Language from "../../Interfaces/Language";

export const fr_ca: Language = {
  common: {
    help: "Vous avez besoin d'aide?",
    helpCenter: "Pages d'aide",
    expect: "Ce à quoi il faut s'attendre lors de ces tests",
    faq: "Foire aux questions",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/awrdc3sw7e-foire-aux-questions-french-canadian",
    copyright: "Droit d'auteur",
    copyrightNotices: "Avis de droit d'auteur",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/3fingajt9c-avis-de-droit-d-auteur-french-canadian",
    terms: "Conditions d'utilisation",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/aju5skzhzh-conditions-d-utilisation-french-france",
    privacy: "Déclaration de confidentialité",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/t0imv0uou0-politique-de-confidentialit-french-canadian",
    dmca: "Politique relative à la DMCA",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/7euem770c5-politique-relative-la-dmca-french-canadian",
    accommodations: "Handicap / Accommodements",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/05157dsuzy-handicap-accessibilit-french-canadian",
    technicalSupport: "Soutien technique",
    chatSupport:
      "Pour obtenir une assistance, rendez-vous dans le coin inférieur droit de cette page pour dialoguer en ligne avec notre équipe."
  },
  instruction: {
    eppCopyright:
      "Cet évaluation comprend du matériel reproduit de SalesAP ©1995 et CSAP ©2002, Multi-Health Systems Inc. Tous droits réservés.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "WTMA Droit d'auteur © 1997, 1999 Psychological Assessment Resources, Inc."
  },
  overview: {
    approx: "Environ",
    minutes: "minutes"
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "Votre évaluation a été mise en pause.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "Veuillez <b>ne pas</b> quitter la fenêtre de votre navigateur ni cliquer en dehors de la fenêtre de l'évaluation pendant que le test est en cours.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "En cas de problèmes liés à votre connexion Internet, veuillez ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "cliquer ici",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " pour obtenir des conseils de dépannage.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "Si vous quittez l’évaluation, vous serez <b>interdit(e) d’accès</b> pour des raisons de sécurité, ce qui pourrait vous empêcher de terminer l’évaluation.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "Veuillez noter que si vous quittez l’évaluation deux fois de plus, vous serez <b>interdit(e) d’accès</b> pour des raisons de sécurité.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>Si vous quittez à nouveau l'évaluation, vous serez interdit(e) d’accès pour des raisons de sécurité.</b>",
    BUTTON_RESUME: "Reprendre"
  },
  error: {
    common: {
      UH_OH: "Oups !"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Chargement impossible en raison de problèmes réseau. Veuillez vérifier votre connexion Internet et réactualiser la page dans quelques instants.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "Si le problème persiste, consultez notre ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "FAQ d’aide",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/awrdc3sw7e-foire-aux-questions-french-canadian"
    }
  }
};
