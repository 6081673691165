import Language from "../../Interfaces/Language";

export const it: Language = {
  common: {
    help: "Hai bisogno di aiuto?",
    helpCenter: "Centro assistenza",
    expect: "Cosa aspettarti da questi test",
    faq: "Domande frequenti",
    faqLink:
      "https://criteriacandidates.helpdocs.io/article/wcfowfbjak-domande-frequenti-italian",
    copyright: "Copyright",
    copyrightNotices: "Avvisi sul copyright",
    copyrightNoticesLink:
      "https://criteriacandidates.helpdocs.io/article/39vzsqkkd1-copyright-italian",
    terms: "Termini d'uso",
    termsLink:
      "https://criteriacandidates.helpdocs.io/article/k1wehcawif-termini-e-condizioni-d-uso-italian",
    privacy: "Informativa sulla privacy",
    privacyLink:
      "https://criteriacandidates.helpdocs.io/article/cwbavza9ya-informativa-sulla-privacy-italian",
    dmca: "Politica DMCA",
    dmcaLink:
      "https://criteriacandidates.helpdocs.io/article/1t4321oocy-politica-del-digital-millenium-copyright-act-italian",
    accommodations: "Disabilità/Assistenza",
    accommodationsLink:
      "https://criteriacandidates.helpdocs.io/article/wa2o27qm2g-disabilit-accessibilit-italian",
    technicalSupport: "Assistenza tecnica",
    chatSupport:
      "Per ricevere assistenza accedi alla chat che si trova in basso a destra di questa pagina"
  },
  instruction: {
    eppCopyright:
      "Questa valutazione include materiale riprodotto da SalesAP ©1995 e CSAP ©2002, Multi-Health Systems Inc. Tutti i diritti riservati.",
    salesApCopyright:
      "SalesAP ©1995, Multi-Health Systems Inc. All rights reserved.",
    csapCopyright: "CSAP ©2002, Multi-Health Systems Inc. All rights reserved.",
    wtmaCopyright:
      "Copyright © WTMA 1997, 1999 Psychological Assessment Resources, Inc."
  },
  lockout: {
    LOCKOUT_TITLE_PAUSED: "La sua valutazione è stata messa in pausa.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_1:
      "<b>Non</b> uscire dalla finestra del browser o non cliccare fuori dall'area della valutazione mentre il test è in corso.",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_1:
      "Se riscontri problemi di connettività a Internet, fai ",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_LINK: "clic qui",
    LOCKOUT_ALERT_MESSAGE_CONNECTIVITY_SECTION_2:
      " per alcuni consigli sulla risoluzione dei problemi.",
    LOCKOUT_ALERT_MESSAGE_PARAGRAPH_2:
      "L’uscita dalla valutazione comporta la tua <b>estromissione</b> per motivi di sicurezza, il che potrebbe impedirti di completare la tua valutazione.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH:
      "L’uscita dalla valutazione per <b>altre 2 volte</b> comporta la tua <b>estromissione</b> per motivi di sicurezza.",
    LOCKOUT_ALERT_MESSAGE_FINAL_PARAGRAPH_AT_LIMIT:
      "<b>L'uscita dalla valutazione una seconda volta comporta la tua estromissione per motivi di sicurezza.</b>",
    BUTTON_RESUME: "Riprendi"
  },
  error: {
    common: {
      UH_OH: "Ops!"
    },
    network: {
      MESSAGE_PARAGRAPH_1:
        "Impossibile caricare la pagina a causa di problemi di rete. Controlla la connessione a Internet e poi aggiorna la pagina.",
      MESSAGE_PARAGRAPH_2_SECTION_1:
        "Se il problema persiste, leggi le nostre ",
      MESSAGE_PARAGRAPH_2_LINK_TEXT: "FAQ per l’assistenza",
      MESSAGE_PARAGRAPH_2_SECTION_2: ".",
      MESSAGE_PARAGRAPH_2_LINK:
        "https://criteriacandidates.helpdocs.io/article/wcfowfbjak-domande-frequenti-italian"
    }
  }
};
